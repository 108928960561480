/**
 * Manage map markers
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 */

/* global _, DEBUG, Promise, google, isGoogleMapsAvailable, MarkerClusterer, zgGetObjectPropertyValue */

(function ( _ ) {
	'use strict';

	// Establish the root object ('window' in the browser)
	var root = this;

	/**
	 * @param {string}  [markerIcon]               image to use as the default marker Icon (path)
	 * @param {boolean} [useMarkerClusterer]       Collapse (cluster) the markers together into groups.A single marker
	 *                                             will be created for the group, with a number specifying how many
	 *                                             marker are grouped inside
	 * @param {string}  [markerClustererImg]       image url. Image to use for the markerClusterers. You have to set the
	 *                                             image url and it's width and height. It will be ignored otherwise the
	 *                                             number will appear in the center of the image, so is better to use a
	 *                                             square/round image (do not use images with a "pointer") Make sure you
	 *                                             set the correct values for the height and with. The image acts as a
	 *                                             repeated background so if you set a smaller size it will crop the
	 *                                             image, and a bigger one will make it repeat the image
	 * @param {string}  [markerClustererImgHeight] image height.
	 * @param {string}  [markerClustererImgWidth]  image width.
	 * @param {string}  [markerClusterGroupBy]
	 * @param {array}   [markerClustererGroup]
	 * @param {int}     [markerClustererMaxZoom]   If the zoom level is over this the cluster will explode into
	 *                                             individual markers. The map will try to zoom over this level before
	 *                                             rendering an infoWindow
	 */
	var DEFAULTS = {
		markerIcon:         null,
		useMarkerClusterer: true,

		markerClustererImg:       null,
		markerClustererImgHeight: null,
		markerClustererImgWidth:  null,

		markerClusterGroupBy: null,
		markerClustererGroup: {},

		markerClustererMaxZoom: 10
	};


	// CLASS DEFINITION
	// ================

	/**
	 *
	 * @param {Object} options
	 * @param {Object} [map]
	 * @param {Object} [items]
	 * @constructor
	 */
	var MapMarkersMgr = function ( options, map, items ) {
		this.options = _.extend( {}, DEFAULTS, root.ZG_CONFIG, options || {} );

		this.map        = map || null;
		this.items      = items || {};
		this.mapMarkers = {};

		// markerClusters
		this.clusters = {};

		this.markerHandler = this.__createMarkerHandler();
	};


	/**
	 *
	 * @returns {Promise}
	 */
	MapMarkersMgr.prototype.clearMarkers = function () {
		return new Promise( (function ( resolve ) {
			this.markerHandler.then( (function () {
				if ( this.__isMarkerClustererEnabled() ) {
					_.each( this.clusters, function ( cluster, clusterId ) {
						this.__clearCluster( clusterId );
					}, this );
				} else {
					_.each( this.mapMarkers, function ( marker ) {
						marker.setMap( null );
					}, this );
				}

				resolve();
			}).bind( this ) );
		}).bind( this ) );
	};


	/**
	 *
	 * @param {string} itemId
	 * @returns {Promise}
	 */
	MapMarkersMgr.prototype.createNew = function ( itemId ) {
		return new Promise( (function ( resolve, reject ) {
			this.markerHandler.then( (function () {
				this.__createMarker( itemId ).then( (function () {
					// add the marker to the map
					this.updateMarker( itemId ).then( resolve, reject );
				}).bind( this ) );
			}).bind( this ) );
		}).bind( this ) );
	};


	/**
	 *
	 * @param {string} itemId
	 * @returns {Promise}
	 */
	MapMarkersMgr.prototype.hide = function ( itemId ) {
		return new Promise( (function ( resolve ) {
			this.markerHandler.then( (function () {
				var clusterId;

				if ( this.mapMarkers[itemId] ) {
					if ( this.__isMarkerClustererEnabled() ) {
						clusterId = this.__getClusterIdFromMarkerId( itemId );

						if ( this.clusters.hasOwnProperty( clusterId ) ) {
							this.clusters[clusterId].removeMarker( this.mapMarkers[itemId] );
						}
					}

					this.mapMarkers[itemId].setMap( null );

					resolve( itemId );
				}
			}).bind( this ) );
		}).bind( this ) );
	};


	/**
	 *
	 * @param {string} [clusterId]
	 * @returns {Promise}
	 */
	MapMarkersMgr.prototype.redraw = function ( clusterId ) {
		return new Promise( (function ( resolve ) {
			this.markerHandler.then( (function () {
				if ( this.__isMarkerClustererEnabled() ) {
					if ( clusterId && this.clusters[clusterId] ) {
						this.clusters[clusterId].redraw();
					} else {
						_.each( this.clusters, function ( cluster ) {
							cluster.redraw();
						}, this );
					}
				}

				resolve();
			}).bind( this ) );
		}).bind( this ) );
	};


	/**
	 *
	 * @param {google.maps.Map} [map]
	 * @returns {Promise}
	 */
	MapMarkersMgr.prototype.setMap = function ( map ) {
		this.map = map || null;

		_.each( this.clusters, function ( cluster ) {
			cluster.setMap( this.map );
		}, this );

		return this.update();
	};

	MapMarkersMgr.prototype.setMapNull = function ( map ) {	
		this.map = map || null;	
		return this.update();	
	};
	
	/**
	 *
	 * @param {Object} [items]
	 * @returns {Promise}
	 */
	MapMarkersMgr.prototype.setItems = function ( items ) {
		this.items = items || {};

		return this.update();
	};


	/**
	 *
	 * Add the marker to the map either directly or using markerClusterer if enabled
	 *
	 * @param {string} itemId
	 * @returns {Promise}
	 */
	MapMarkersMgr.prototype.show = function ( itemId ) {
		return new Promise( (function ( resolve ) {
			this.markerHandler.then( (function () {
				var clusterId;

				if ( this.mapMarkers[itemId] ) {
					if ( this.__isMarkerClustererEnabled() ) {
						// use marker clusters
						clusterId = this.__getClusterIdFromMarkerId( itemId );

						// create the markerClusterer if it does not exist
						this.__createCluster( clusterId ).then( (function ( cluster ) {
							// add marker to the clusterer
							cluster.removeMarker( this.mapMarkers[itemId] );
							cluster.addMarker( this.mapMarkers[itemId] );
							resolve( itemId );
						}).bind( this ) );
					} else {
						// add marker directly to the map
						this.mapMarkers[itemId].setMap( this.map );
						resolve( itemId );
					}
				}
			}).bind( this ) );
		}).bind( this ) );
	};


	/**
	 *
	 * @returns {Promise}
	 */
	MapMarkersMgr.prototype.update = function () {
		return new Promise( (function ( resolve ) {
			this.markerHandler.then( (function () {
				this.clearMarkers().then( (function () {
					_.each( this.items, function ( item, itemId ) {
						this.createNew( itemId );
					}, this );

					resolve();
				}).bind( this ) );
			}).bind( this ) );
		}).bind( this ) );
	};


	/**
	 *
	 * @param {string} itemId
	 * @returns {Promise}
	 */
	MapMarkersMgr.prototype.updateMarker = function ( itemId ) {
		return new Promise( (function ( resolve ) {
			this.markerHandler.then( (function () {
				if ( this.__isValid( itemId ) ) {
					this.show( itemId );
				} else {
					this.hide( itemId );
				}

				resolve( itemId );
			}).bind( this ) );
		}).bind( this ) );
	};


	// ------------------------------------------------------------------------
	// PRIVATE METHODS

	/**
	 *
	 * @param {string} clusterId
	 * @private
	 */
	MapMarkersMgr.prototype.__clearCluster = function ( clusterId ) {
		if ( this.clusters[clusterId] ) {
			this.clusters[clusterId].clearMarkers();
		}
	};


	/**
	 *
	 * @param clusterId
	 * @returns {Promise}
	 * @private
	 */
	MapMarkersMgr.prototype.__createCluster = function ( clusterId ) {
		return new Promise( (function ( resolve, reject ) {
			var clusterOptions;
			var clusterGroupOptions;
			var height;
			var img;
			var width;

			if ( this.clusters[clusterId] ) {
				resolve( this.clusters[clusterId] );
			} else if ( this.map ) {
				clusterOptions = {
					gridSize:           50,
					maxZoom:            this.options.markerClustererMaxZoom,
					zoomOnClick:        true,
					averageCenter:      true,
					minimumClusterSize: 3,
				};

				clusterGroupOptions = this.options.markerClustererGroup && this.options.markerClustererGroup[clusterId] || {};

				img    = clusterGroupOptions.img || this.options.markerClustererImg;
				height = clusterGroupOptions.imgHeight || this.options.markerClustererImgHeight;
				width  = clusterGroupOptions.imgWidth || this.options.markerClustererImgWidth;

				if ( img && height && width ) {
					clusterOptions.styles = [{
						textColor: "white",
						textSize: 16,
						url:    img,    // Image to use as background for the cluster marker
						height: height, // image height
						width:  width   // image width
					}];
				}

				this.clusters[clusterId] = new MarkerClusterer( this.map, [], clusterOptions );

				resolve( this.clusters[clusterId] );
			}
		}).bind( this ) );
	};


	/**
	 *
	 * @param {string} itemId
	 * @returns {Promise}
	 * @private
	 */
	MapMarkersMgr.prototype.__createMarker = function ( itemId ) {
		return new Promise( (function ( resolve ) {
			var markerData;

			if ( !this.mapMarkers[itemId] ) {
				markerData = this.__generateData( itemId );

				if ( markerData ) {
					// create marker
					this.mapMarkers[itemId] = new google.maps.Marker( markerData );

					// Open infoWindow on click
					google.maps.event.addListener( this.mapMarkers[itemId], 'click', function () {
						$( document ).trigger( 'zg.mapMarkersMgr.clickOnMarker', [itemId] );
					} );
				}
			}

			resolve( this.mapMarkers[itemId] );
		}).bind( this ) );
	};


	/**
	 *
	 * @returns {Promise}
	 * @private
	 */
	MapMarkersMgr.prototype.__createMarkerHandler = function () {
		return new Promise( (function ( resolve, reject ) {
			if ( !isGoogleMapsAvailable() ) {
				reject( new Error( 'StoreLocator.mapMarkersMgr - FAILED' ) );
			}

			if ( this.options.useMarkerClusterer && !root.MarkerClusterer ) {
				$.ajax( {
					url:      '/themes/' + root.SGL_JS_MERCHANT_ID + '/' + root.SGL_JS_THEME + '/js/lib/markerclusterer.js',
					dataType: 'script',
					cache:    false, // always load latest version

					success: function () {
						resolve();
					},

					error: function () {
						this.options.useMarkerClusterer = false;

						if ( DEBUG ) {
							console.error( 'StoreLocator.mapMarkersMgr.init - markerClusterer library could not be loaded' );
						}

						resolve();
					}
				} );
			} else {
				resolve();
			}
		}).bind( this ) );
	};


	/**
	 *
	 * @param {string} itemId
	 *
	 * @returns {
	 *		{
	 *			position: google.maps.LatLng,
	 *			zoomOnClick: boolean,
	 *			flat: boolean,
	 *			title: string,
	 *			storeId: (string|number),
	 *			icon: ?string=
	 *		}
	 *		|null
	 *	}
	 */
	MapMarkersMgr.prototype.__generateData = function ( itemId ) {
		var markerData = null;
		var item       = this.items[itemId];
		var icon;
		var position;

		if ( item && !isNaN( item.fields.Latitude ) ) {
			// get marker position
			position = new google.maps.LatLng( +item.fields.Latitude, +item.fields.Longitude );
			
			
			//IF is Blauer HT distributor
			if (item.fields.Type === 'Distributor') {
				icon = '/themes/' + root.SGL_JS_MERCHANT_ID + '/' + root.SGL_JS_THEME + '/images/storeLocator/distributor-marker.png';	
			} else {
				icon = item.fields.Marker || this.options.markerIcon || null;
			}

			// create marker options
			markerData = {
				position:    position,
				zoomOnClick: true,
				flat:        true,
				title:       ( '' + item.fields.City + ' - ' + item.fields.Name ),
				storeId:     itemId
			};

			if ( icon ) {
				markerData.icon = icon;
			}
		}

		return markerData;
	};


	/**
	 *
	 * @param {string} itemId
	 * @returns {string}
	 */
	MapMarkersMgr.prototype.__getClusterIdFromMarkerId = function ( itemId ) {
		var clusterId = 'default';
		var temp;

		if ( this.options.markerClusterGroupBy ) {
			temp = zgGetObjectPropertyValue( this.items[itemId], this.options.markerClusterGroupBy );

			if ( !_.isUndefined( temp ) && !_.isNull( temp ) ) {
				clusterId = temp;
			}
		}

		return clusterId;
	};


	/**
	 *
	 * @private
	 * @returns {boolean}
	 */
	MapMarkersMgr.prototype.__isMarkerClustererEnabled = function () {
		return this.options.useMarkerClusterer && root.MarkerClusterer;
	};


	/**
	 * Check if an item is valid (should be displayed)
	 *
	 * @private
	 * @param {string} itemId
	 * @returns {boolean}
	 */
	MapMarkersMgr.prototype.__isValid = function ( itemId ) {
		var valid = false;

		if ( this.items && this.items[itemId] ) {
			valid = true;
		}

		return valid;
	};


	// -----------------------------------------------------------------------------------------------------------------

	root.ZgStoreLocatorMapMarkersMgr = MapMarkersMgr;

}.call( this, _ ));
