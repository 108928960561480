/**
 * 2102-zg-getProductInfo.js
 *
 * Requests the information for products and display the result retrieved.
 * The request can include the proper parameters in order to get the products filtered, paginated and/or sorted from backend side.
 *
 * @author: Dario Lopez <dlopez[at]kooomo.com>
 *
 */


(function ( $, _ ) {
    'use strict';

    // Establish the root object ('window' in the browser)
    var root = this;

    /**
     * @selector data-zg-role="get-product-bck" The plugin start if there is the selector in the dom when the page load
     */
    var SELECTOR = '[data-zg-role="get-product-bck"]';

    // url for the AJAX request
    var REQUEST_URL = root.makeUrl( { module: 'eshop', manager: 'eshop', action: 'getProductsInfo' } );

    /**
     * @param {string|int} [categoryId] Category id if you want display list of products of specific category
     * @param {string} [products] List of products id, if you want display specific products
     * @param {boolean} [forceResult] Forces to get the product info even if the shop configuration would prevent it
     * @param {boolean} [getCategories] If you want category associated in the object of each product
     * @param {string|boolean} [getCharacteristics] If you want caratteristics of each single product
     * @param {boolean} [getClassification]  If you want classification of each single product
     * @param {boolean} [getCustomValues]  If you want custom value of each single product
     * @param {boolean} [getDescriptions]  If you want descriptions of each single product
     * @param {string|boolean} [getImages]  If you want images of each single product
     * @param {boolean} [getImages360]  If you want the 360 image view of each single product
     * @param {boolean} [getLinkedProducts]  If you want linked product of each single product
     * @param {boolean} [getNextPrev]  If you want next and prev product of each single product
     * @param {string|boolean} [getOptions]  If you want options of each single product
     * @param {boolean} [getQuantity]  If you want warehouse quantity of each single product
     * @param {boolean} [getPrice]  If you want price of each single product
     * @param {boolean} [getPricePerCountry]  If you want price for country of each single product
     * @param {boolean} [getPromotions]  If you want promotions associated of each single product
     * @param {boolean} [getPhysicalCharacteristics]
     * @param {boolean} [getProperties]
     * @param {boolean} [getSeo] get the SEO url
     * @param {boolean} [getSkus]
     * @param {boolean} [getStandardOptions]
     * @param {boolean} [getTextile] If you want textile declaration for each single product
     * @param {boolean} [getUrl]
     * @param {boolean} [getFilterCategories] If you want filter by categories
     * @param {boolean} [getFilterCharacteristics] If you want filter by characteristics
     * @param {boolean} [getFilterClassification] If you want filter by classification
     * @param {boolean} [getFilterOptions] If you want filter by options
     * @param {boolean} [getFilterProperties] If you want filter by proprieties
     * @param {boolean} [getFilterPrice] If you want filter by price
     * @param {boolean} [getFilterPromotions] If you want filter by promotion
     */
    var DEFAULT_REQUEST = {
        categoryId: null,
        products: null,
        // You could use this to force a language in the result
        // lang: false,
        forceResult: false,
        getCategories: true,
        getCharacteristics: false,
        getClassification: false,
        getCustomValues: false,
        getDescriptions: false,
        getImages: false,
        getImages360: false,
        getLinkedProducts: false,
        getNextPrev: false,
        getOptions: false,
        getQuantity: false,
        getPrice: false,
        getPricePerCountry: false,
        getPromotions: false,
        getPhysicalCharacteristics: false,
        getProperties: false,
        getSeo: false,
        getSkus: false,
        getStandardOptions: false,
        getTextile: false,
        getUrl: false,
        getFilterCategories: false,
        getFilterCharacteristics: false,
        getFilterClassification: false,
        getFilterOptions: false,
        getFilterProperties: false,
        getFilterPrice: false,
        getFilterPromotions: false,
        pageSize: 5,
        page: 0
    };


    var DEFAULTS = {
        type: 'product',
        namespace: null,
        productsTemplate: null,
        errorTemplate: 'product-error',
        productsContainer: null,

        paginationXYelements:  '[data-zg-role="x-y-elements"]',
        paginationNumberPages: '[data-zg-role="pagination-number-pages"]',
        paginationGoTo:        '[data-zg-role="pagination-go-to-page"]',
        paginationControls:    '[data-zg-role="pagination-controls"]',
        elementControlScroller:'#site_footer',
        paginationLoadMoreBtn: '#pagination-load-more',
        paginationNext:        '#pagination-next',
        paginationPrev:        '#pagination-prev',

        templateFilterList:   'filter-list',
        templateFilterReset:  'filter-reset',
        templateFilterSearch: 'filter-search',
        templateFilterSlider: 'filter-slider',
        isFirstLoad:    true,
        emptyContainer: true,
        filterElement:  '[data-zg-action]',
        resetFilters:   '[data-zg-role="reset-filters"]',
        validParameters:'[data-valid-parameters]',
        enableReset:    true,
        enableSearch:   false,
        searchButton:   '[data-zg-role="filter-search-btn"]',
        searchInFields: null,
        searchTypeaheadFields: false,
        sliderMinDiff:  50,
        sliderStep:     10,
        sliderMin:      0,
        sliderMax:      0,
        sortFilters:    true,
        sortFiltersBy:  'id',
        elementSorting: '[data-zg-role="pagination-sort-bck"]',
        elementCounter: '[data-zg-role="pagination-counter"]',
        paginationType: 'pages',
        total:          0,
        totalPages:     0
    };


    // SKELETON-PLUGIN CLASS DEFINITION
    // ===========================

    /**
     * @param {HTMLElement} element
     * @param {!Object}     options
     *
     * @constructor
     */
    var GetProductInfo = function ( element, options ) {
        this.$element = $( element );

        this.options = {};
        this.request = {};
        this.updateOptions( options );

        // container to render the products.
        // if the option 'productsContainer' is not set it will use the original element
        this.$container = this.options.productsContainer ? $( this.options.productsContainer ) : this.$element;

        // responses cache.
        this.cached = {};

        this.$filterContainer       = $( this.options.filterContainer );

        this.stopScrollEvent        = true;
        this.$paginationXYelements  = $( this.options.paginationXYelements );
        this.$paginationNumberPages = $( this.options.paginationNumberPages );
        this.$paginationNext        = $( this.options.paginationNext );
        this.$paginationPrev        = $( this.options.paginationPrev );
        this.$paginationControls    = $( this.options.paginationControls );
        this.$paginationLoadMoreBtn = $( this.options.paginationLoadMoreBtn );
        this.$controlScroller       = $( this.options.elementControlScroller );
        this.$elementSorting        = $( this.options.elementSorting );

        this.__processFilters();
        this.__setEventHandlers();
    };


    GetProductInfo.prototype.__processFilters = function(){
        //Applying filters according to the query string parameters
        var filter = {};
        var data = $.uriMgr( { action: 'getStatus' } ).components;

        _.each( data, function( values, index ){
            // If the query string parameter is one of the "valid ones" (added by data-attribute).
            if( _.isArray( values, index ) && _.contains( this.options.validParameters, index ) ){

                if( index === 'search' ) {
                    this.request.page = 0;

                    var searched_string = values[0];
                    var searched = {value: searched_string, fields: ['name', 'option', 'mainoption']};

                    this.request.filterBy = {};
                    this.request.filterBy.search = searched;
                }
                else {
                    if( index === 'sortBy' ){
                        this.request.sortBy = values[0];
                    }
                    else {
                        //The Price filter values does not need to be stored as an Array but only getting the first value of the "values" array
                        if( index === 'price-max' || index === 'price-min' ){
                            filter[ index ] = values[0];
                        }
                        else if (index === 'page') {
                            this.request.page = values[0];
                        }
                        else {
                            filter[ index ] = values;
                        }

                        this.request.filterBy = _.extend(this.request.filterBy || {}, filter);
                    }
                }
            }
        }, this);
    };



    /**
     * Request the product information from the server.
     * It uses the request data to generate a cache object, so the same request is not executed
     * twice.
     * The cache is created as a js object, reloading the page will clean the cache
     *
     */
    GetProductInfo.prototype.getInfo = function ( createFilter, updateUrl ) {
        var cacheKey;

        if ( this.request.categoryId || this.request.products ) {
            cacheKey = JSON.stringify( this.request );

            if ( this.cached[cacheKey] ) {
                this.__onBeforeSend();
                this.cached[cacheKey].createFilter = false;
                this.cached[cacheKey].updateUrl = true;
                this.__onSuccess( this.cached[cacheKey] );
                this.__onComplete();
            } else {
                $.ajax( {
                    type:     'POST', // 'GET' breaks on the search version (too many parameters)
                    url:      REQUEST_URL,
                    dataType: 'json',
                    data:     this.request,

                    beforeSend: function () {
                        this.__onBeforeSend();
                    }.bind( this ),

                    success: function ( response ) {
                        response.createFilter = createFilter;
                        response.updateUrl = updateUrl;

                        this.__onSuccess( response );
                        this.cached[cacheKey] = response;
                    }.bind( this ),

                    error: function ( response ) {
                        this.__onError( response );
                    }.bind( this ),

                    complete: function () {
                        this.__onComplete();
                    }.bind( this )
                } );
            }
        } else if ( DEBUG ) {
            console.warn( 'GetProductInfo.getInfo - not requested: "categoryId" and "products" missing' );
        }
    };


    /**
     * Destroy the previous products in the container if the 'empty' option is true and tries to
     * unset the event listeners.
     * Sends the product information to renderProducts.
     * Only used if we didn't request filters and we specified the option 'productsTemplate'
     *
     * @param {Array} products
     *
     * @private
     */
    GetProductInfo.prototype.__createProducts = function ( products ) {
        this.__emtpyContainer();
        this.__renderProducts( products );
    };

    GetProductInfo.prototype.__emtpyContainer = function() {
        if ( this.options.emptyContainer ) {
            // remove the product events
            this.$container.find( '[data-zg-role="product"]' ).each( function () {
                var productData = $( this ).data( 'zg.product' );

                if ( productData ) {
                    $( document ).off( '.' + productData.options.namespace );
                }
            } );
            this.$container.empty();
            this.options.emptyContainer = false;
        }
    };


    /**
     * Render the products we received using the handlebars template specified in 'productsTemplate'
     *
     * @param {Array} products
     *
     * @private
     */

    /**
     * @method __renderProducts
     * @fires document#zg.getProductInfo.productCreated Products rendered using the handlebars template specified in 'productsTemplate'
     */
    GetProductInfo.prototype.__renderProducts = function ( products ) {
        _.each( products || [], function ( product, index ) {
            var $item = $( handlebarsTemplates.render( this.options.productsTemplate, product ) );

            this.$container.append( $item );

            // Fade-in in using bootstrap classes.
            setTimeout(
                function () {
                    $item.addClass( 'in' );
                }, index * 150
            );

            if ( DEBUG ) {
                console.log( 'GetProductInfo - renderProducts', product );
            }

            $( document ).trigger( 'zg.getProductInfo.productCreated', [$item, this.options, product] );
        }, this);
    };


    /**
     * Successful AJAX request.
     * Process the product information.
     *
     * Sends the product (and filters) information to the next step (see below)
     *
     * @param {Object} response
     *
     * @private
     */

    /**
     * @method __onSuccess
     * @fires document#zg.getProductInfo.success On ajax call success, products and filters are rendered
     */
    GetProductInfo.prototype.__onSuccess = function ( response ) {
        if ( DEBUG ) {
            console.log( 'GetProductInfo - SUCCESS', response );
        }

        if ( response.products.length > 0 ) {
            this.__processProducts( response.products );
        }
        else {
            // The Ajax call will retrieve no products. This could be happening for trying to fetch products from a Page number
            // that does not contains any products. What we do is to set the Page property as 0 and then we call the getInfo
            // function again. This case will be executed only one time
            if( this.options.isFirstLoad ) {
                this.request.page = 0;
                this.options.isFirstLoad = false;
                this.getInfo();
            }
        }

        if ( ( response.filters ) && response.createFilter ){
            // If we requested filters we send the product information to the the filter plugin.
            // Let the filters take over.
            this.__createFilters( response.filters, (response.products || []), response.url );
        }
        if ( ( response.filters ) && response.updateUrl ) {
            this.__updateURL( response );
        }

        if ( response.products && this.options.productsTemplate ) {
            // If a template was set up for the products we render them.
            this.page = response.pagination.page;
            this.pageSize = response.pagination.pageSize;
            this.total = response.pagination.total;
            this.totalPages = Math.floor( response.pagination.total / response.pagination.pageSize );

            this.__createProducts( response.products );
            this.updateCounters();
        }

    }


    /**
     * Execute before sending the AJAX request.
     * Sets the container as 'loading'.
     *
     * @private
     */

    /**
     * @method __onBeforeSend
     * @fires document#zg.getProductInfo.start Execute before sending the AJAX request.
     */
    GetProductInfo.prototype.__onBeforeSend = function () {
        this.$container.parent().prepend( '<div class="loading"></div>' );
        $( document ).trigger( 'zg.getProductInfo.start' );
    };


    /**
     * Completed AJAX request.
     * Removes the 'loading' class from the container.
     *
     * @private
     */

    /**
     * @method __onComplete
     * @fires document#zg.getProductInfo.complete Completed AJAX request.
     */
    GetProductInfo.prototype.__onComplete = function () {
        this.$container.parent().find( '.loading' ).removeClass( 'loading' );
        $( document ).trigger( 'zg.getProductInfo.complete' );
    };


    /**
     * Failed AJAX request.
     * Empties the container if the option is set and renders the error template
     *
     * @param {Object} response
     *
     * @private
     */

    /**
     * @method __onError
     * @fires document#zg.getProductInfo.error AJAX request make an error
     */

    /**
     * @method __onError
     * @fires document#zg-error Display error message if ajax request make an error
     */
    GetProductInfo.prototype.__onError = function ( response ) {
        if ( DEBUG ) {
            console.log( 'GetProductInfo - ERROR', response );
        }

        if ( this.options.emptyContainer ) {
            this.$container.empty();
        }

        // if there is an error template we add it in the target. otherwise we display an error message
        if ( this.options.errorTemplate ) {
            this.$container.append(
                handlebarsTemplates.render( this.options.errorTemplate, response || {} )
            );
        } else {
            $( document ).trigger( 'zg-error', [{
                message: root.JS_TRANSLATIONS.genericErrorMsg
            }] );
        }

        $( document ).trigger( 'zg.getProductInfo.error' );
    };


    //Adding the applied filters to the query string
    GetProductInfo.prototype.__updateURL = function( response ){
        var request, availableKeys = {}, appliedFilters = {};

        if( response ) {
            availableKeys = _.allKeys(response.filters);
        }

        if( this.request.filterBy ) {
            appliedFilters = this.request.filterBy;

            if (appliedFilters.hasOwnProperty('search')) {
                //We only need to add the searched string to the URL, not the rest of the search object
                var searchedValue = this.request.filterBy.search.value;
                delete appliedFilters.search;
                _.extend(appliedFilters, {search: searchedValue});
            }
        }

        if( response && response.pagination.page && this.options.paginationType == 'pages'){
            //availableKeys.push( 'page' );
            appliedFilters = _.extend( { page: response.pagination.page }, appliedFilters );
        }
        if( this.request.sortBy ){
            //availableKeys.push( 'sortBy' );
            appliedFilters = _.extend( { sortBy: this.request.sortBy }, appliedFilters );
        }
        if( this.request.filterBy && this.request.filterBy.search ){
            //availableKeys.push( 'search' );
            appliedFilters = _.extend( { search: this.request.filterBy.search.value }, appliedFilters );
        }

        request = {
            applied:   appliedFilters,
            available: this.options.validParameters,
            data:      { categoryId: + (this.request.categoryId) }
        };

        $.uriMgr( request );

        /*
        if( this.request.sortBy ){
            //If the sortBy property is empty, we remove it. We do not need them to update the URL anymore
            if( Object.entries(this.request.sortBy).length === 0 && this.request.sortBy.constructor === Object ) {
                delete this.request.sortBy;
            }
        }
        */
    };


    /**
     * show / update the items counter
     *
     */
    GetProductInfo.prototype.updateCounters = function () {
        this.$counter
            .hide()
            .text( this.total )
            .fadeIn();

        switch( this.options.paginationType ){
            case 'pages':
                this.__paginatePages();
                break;
            case 'load-more':
                this.__paginateLoadMore();
                break;
            case 'infinite':
                this.stopScrollEvent = false;
                $( window ).on( 'scroll.zg.pagination.loader', { that : this }, this.__scrollOn );
                this.options.emptyContainer = false;
                break;
        }
    };


    GetProductInfo.prototype.__paginateLoadMore = function (){
        var x = this.pageSize;

        this.options.emptyContainer = true;

        if( this.page > 0 ){
            x = this.pageSize * ( this.page + 1 );
        }
        this.$paginationLoadMoreBtn.find( 'button').data( 'page', ( this.page + 1 ) );
        this.$paginationXYelements.find( 'span:first-child' ).html( x );
        this.$paginationXYelements.find( 'span:nth-child(2)' ).html( this.total );

        if( x < this.total ) {
            this.$paginationLoadMoreBtn.removeClass( 'hidden' );
        }
        else {
            this.$paginationLoadMoreBtn.addClass( 'hidden' );
        }
    };


    GetProductInfo.prototype.__paginatePages = function (){
        var lessPages, morePages = false, diffPages;
        var counter = 2;
        var i = 1;

        if( this.totalPages >= 1 ) {
            this.$paginationControls.removeClass( 'hidden' );

            //START: Calculate the number of pages to display
            if ( this.totalPages === 1 ) {
                counter = 2;
            }
            else if ( this.page > 1 && this.page < 4 ) {
                counter = this.page + 1
            }
            else if ( this.page >= 4 ) {
                i = this.page - 1;
                counter = i + 2;
            }

            if ( this.page >= 4 ) {
                lessPages = true;
            }
            if ( this.totalPages > 3 && ( this.totalPages - this.page > 2 )) {
                morePages = true;
            }
            if( this.totalPages - this.page < 3 ){
                diffPages = this.totalPages - 3;
                if( diffPages > 0 ) {
                    i = diffPages;
                }
                counter = this.totalPages - 1;
            }
            //END: Calculate the number of pages to display


            //START: Enable/disable Previous/Next button
            if ( this.page === 0 ) {
                this.$paginationPrev.prop( 'disabled', true );
                this.$paginationNext.prop( 'disabled', false );
            }
            else {
                this.$paginationPrev.prop( 'disabled', false );
                this.$paginationPrev.data( 'page', ( this.page - 1 ));

                if ( this.page === ( this.totalPages - 1 ) || this.page === 1 && this.totalPages === 1 ) {
                    this.$paginationNext.prop( 'disabled', true );
                }
                else {
                    this.$paginationNext.prop( 'disabled', false );
                }
            }
            this.$paginationNext.data( 'page', ( this.page + 1 ) );
            //END: Enable/disable Previous/Next button


            //Emptying the Pagination container
            this.$paginationNumberPages.empty();

            //The page 1 will always be rendered
            this.__renderPaginationItem( 0 );


            if ( lessPages ) {
                this.$paginationNumberPages.append('<span>...</span>');
            }

            while( i <= counter ){
                this.__renderPaginationItem( i );
                i++;
            }

            if ( morePages ) {
                this.$paginationNumberPages.append('<span>...</span>');
                this.__renderPaginationItem( this.totalPages - 1 );
            }

        }
        else{
            this.$paginationControls.addClass( 'hidden' );
        }
    };

    GetProductInfo.prototype.__renderPaginationItem = function ( i, isDisabled ) {
        var $item, data = {};

        data['i'] = i + 1;
        data['pageTo'] = i;
        data['isActive'] = false;
        if ( this.page == ( i )) {
            data.isActive = true;
        }
        if( isDisabled ) {
            data['isDisabled'] = true;
        }
        $item = $(handlebarsTemplates.render('pagination-item', data));
        this.$paginationNumberPages.append($item);
    };



    /**
     * Initialize the filter plugin.
     * Only used if we requested filters.
     *
     * @param {Array}  [filters]
     * @param {Array}  [products]
     * @param {string} [url]
     *
     * @private
     */
    GetProductInfo.prototype.__createFilters = function ( filters, products, url ) {
        var $containers = [],
            containers = {
                defaultContainer: []
            };

        // create normal filters
        _.each( filters, function ( filter, filterId ) {
            this.__renderSingleFilter( filterId, filter, containers );
        }, this );

        // create search
        if ( this.options.enableSearch ) {
            //filters = $.merge( this.createFilter( 'search', this.appliedFilters.search ), filters );
            /*
            this.__renderSingleFilter( 'search', {
                value: this.appliedFilters.search,
                typeahead: this.__createSearchTypeahead()
            }, containers );
            */
            this.__renderSingleFilter( 'search', {
                value: "",
                typeahead: ""
            }, containers );
        }

        // create reset
        if ( this.options.enableReset ) {
            this.__renderSingleFilter( 'reset', null, containers );
        }

        _.each( containers, function ( filtersArray, containerSelector ) {
            var $container = this.$initialContainer;

            // sort options
            if ( this.options.sortFilters ) {
                filtersArray.sort( zg_sortElements( {
                    attr:              this.options.sortFiltersBy,
                    pattern:           _.isArray( this.options.sortFilters ) ? this.options.sortFilters : null,
                    avoidNumbersOnTop: true
                } ) );
            }

            if ( containerSelector !== 'defaultContainer' ) {
                $container = $(containerSelector);
            }

            this.$filterContainer.html( filtersArray ).removeClass('loading');

            //this.$filterContainer = $.merge( $container, $containers );
        }, this );

        //Setting as active the selected filter by the query string
        if( this.options.isFirstLoad ) {
            _.each(this.request.filterBy, function (filter_values, filter_Key) {
                //Removing the "All" option
                $( '[data-filter="' + filter_Key + '"][data-zg-action="reset"]' ).removeClass('active')

                _.each(filter_values, function (value) {
                    $( '[data-filter="' + filter_Key + '"][data-value="' + value + '"]' ).addClass('active')
                });
            });

            if( this.request.sortBy ){
                //Removing the Active class from the first Sorting option
                $( '[data-zg-role="pagination-sort-bck"]li.active' ).removeClass( 'active' );

                //Adding the Active class to the selected sorting option
                $( '[data-sort-by=' + this.request.sortBy + ']' ).parent().addClass( 'active' );
            }
            this.options.isFirstLoad = false;
        }

        this.$counter = $( this.options.elementCounter );

        $( document ).trigger( 'filters.renderFilters', [this.$element, this.filters, this.appliedFilters, this.$filterContainer] );
    };


    /**
     *
     * @param filterId
     * @param filterObject
     * @param containers
     * @private
     */
    GetProductInfo.prototype.__renderSingleFilter = function ( filterId, filterObject, containers ) {
        var $item,
            containerId,
            filterOptions;

        if ( filterId && containers ) {
            if ( filterObject ) {
                filterObject.id = filterId;
            }

            filterOptions = this.__getFilterOptions( filterId );
            containerId = filterOptions.container || 'defaultContainer';

            $item = this.createFilter( filterId, filterObject, filterOptions.template );

            if ( $item ) {
                if ( !containers[containerId] ) {
                    containers[containerId] = [];
                }
                $.merge( containers[containerId], $item );

            } else {
                console.warn( 'Invalid filter', filterId );
            }
        }
    };


    /**
     *
     * @param {string} filterId
     * @returns {Object}
     * @private
     */
    GetProductInfo.prototype.__getFilterOptions = function ( filterId ) {
        var options = {};

        if ( this.options.filtersOptions && _.isObject( this.options.filtersOptions[filterId] ) ) {
            options = this.options.filtersOptions[filterId];
        }

        return options;
    };


    /**
     *
     * @param {string}  filterId
     * @param {Object=} data
     * @param {string}  template
     *
     * @returns {*}
     */
    GetProductInfo.prototype.createFilter = function ( filterId, data, template ) {
        var $filter;

        switch ( filterId ) {
            case 'price':
                if ( (data.max - data.min) >= this.options.sliderMinDiff ) {
                    $filter = this.createSlider( filterId, handlebarsTemplates.render( template || this.options.templateFilterSlider, data ), data );
                } else if ( DEBUG ) {
                    console.info( 'price filter not created: Price difference under minimum limit' );
                }
                break;

            case 'reset':
                $filter = $( handlebarsTemplates.render( template || this.options.templateFilterReset ) );
                break;

            case 'search':
                $filter = $( handlebarsTemplates.render( template || this.options.templateFilterSearch, data ) );
                break;

            default:
                $filter = $( handlebarsTemplates.render( template || this.options.templateFilterList, data ) );
        }

        if ( $filter ) {
            $filter.data( this.options.sortFiltersBy, filterId );
        }

        return $filter;
    };


    /**
     *
     * @param {string}             filterId
     * @param {string|HTMLElement} item - html String created by handlebars
     * @param {Object}             data - filter information
     *
     * @returns {*|HTMLElement}
     */
    GetProductInfo.prototype.createSlider = function ( filterId, item, data ) {
        var that        = this,
            selRange    = '[type="range"]',
            minVal      = Math.floor( data.min / 10 ) * 10,
            maxVal      = Math.ceil( data.max / 10 ) * 10,
            initial     = [
                that.request.filterBy && that.request.filterBy[filterId + '-min'] && _.isNumber( +that.request.filterBy[filterId + '-min'][0] ) ?
                    +that.request.filterBy[filterId + '-min'] : minVal,
                that.request.filterBy && that.request.filterBy[filterId + '-max'] && _.isNumber( +that.request.filterBy[filterId + '-max'][0] ) ?
                    +that.request.filterBy[filterId + '-max'] : maxVal
            ],
            $item       = $( item ),
            $filterInfo = $( '.slider-value', $item ),
            $slider     = $( '[data-role="rangeslider"]', $item),
            $ranges     = $( selRange, $slider);

        that.options.sliderMin = minVal;
        that.options.sliderMax = maxVal;

        // Set attr to the two inputs
        $ranges.attr({
            min:    minVal,
            max:    maxVal,
            step:   that.options.sliderStep
        });
        $ranges.each(function(i) {
            $( this ).attr({ value: initial[i] });
        });

        function sliderHandler ( values ) {
            if( !that.request.filterBy ){
                that.request.filterBy = {};
            }
            if( values[0] > minVal ){
                that.request.filterBy[filterId + '-min'] = values[0];
            }
            else{
                delete that.request.filterBy[filterId + '-min'];
            }

            if( values[1] < maxVal ){
                that.request.filterBy[filterId + '-max'] = values[1];
            }
            else{
                delete that.request.filterBy[filterId + '-max'];
            }

            //if( that.options.paginationType == "infinite" || that.options.paginationType == "load-more" ){
                that.options.emptyContainer = true;
                that.request.page = 0;
                that.__emtpyContainer();
            //}

            that.getInfo( false, true );
        }

        function sliderText ( values ) {
            $filterInfo.html(
                window.renderPrice ( values[0] ) +
                ' &nbsp;&nbsp; - &nbsp;&nbsp; ' +
                window.renderPrice ( values[1] )
            );
        }

        // Init jquery mobile rangeslider
        $slider.rangeslider({
            create: function( event, ui ) {}
        });

        $slider.on( 'mouseup touchend', function ( e, isReset ){
            var ranges = $slider.find( selRange ),
                values = [];
            if( isReset ){
                values = [ that.options.sliderMin, that.options.sliderMax ];
            }
            else{
                values = [
                    $( ranges[0] ).val(),
                    $( ranges[1] ).val()
                ];
            }
            sliderText( values );
            sliderHandler( values );
        } );

        sliderText( initial );

        return $item;
    };


    /**
     * This one should be kinda self explanatory...  ;)
     */
    GetProductInfo.prototype.resetFilters = function () {
        var $searchInput = $( '[data-zg-role="filter-search"]' );

        this.request.page = 0;
        this.options.emptyContainer = true;
        this.__emtpyContainer();

        if( $searchInput.val(). length) {
            $searchInput.val( '' );
        }

        delete this.request.filterBy;
        if( this.request.sortBy ) {
            //this.request.sortBy = {};
            delete this.request.sortBy;

            // Setting as active the first dropdown element after resetting the filters
            this.$elementSorting.removeClass( 'active' );
            var liElements = this.$elementSorting.parent().children();
            liElements.first().addClass( 'active' );
        }


        $( '.content-filter' ).find( 'a.active' ).removeClass( 'active' );
        $( '.content-filter' ).find( 'a:first-child' ).addClass( 'active' );

        this.__updatePriceSlider( this.options.sliderMin, this.options.sliderMax );

        $('body,html').animate({
            scrollTop: 0
        }, 500);

        this.getInfo();
    };


    GetProductInfo.prototype.__updatePriceSlider = function( minValue, maxValue ) {
        // Resetting filter price
        $( '#range-1a' ).val( minValue || 0 );
        $( '#range-1b' ).val( maxValue || 0 );
        $( '.slider-value' ).html(
            window.renderPrice ( minValue || 0 ) +
            ' &nbsp;&nbsp; - &nbsp;&nbsp; ' +
            window.renderPrice ( maxValue || 0 )
        );
        $( '[data-role="rangeslider"]' ).rangeslider( 'refresh' );
    }


    /**
     * Process the products we received form the server.
     *
     * @param {Array} products
     * @private
     */
    GetProductInfo.prototype.__processProducts = function ( products ) {
        _.each( products || [], function ( product ) {
            // set the type of the requested product
            product.type = this.options.type;

            if ( this.request.categoryId ) {
                product.currentCategory = this.request.categoryId;
            }
        }, this );
    };



    /**
     * Process the request object to remove unnecessary information.
     * We go through all properties in the request and remove the falsy ones and any not present
     * in the original DEFAULT_REQUEST
     *
     * @param {Object} config
     * @private
     */
    GetProductInfo.prototype.__processRequest = function ( config ) {
        var param;
        var value;
        var request = {};

        for ( param in DEFAULT_REQUEST ) {
            // Filter by values by the default properties and not falsy values.
            // We don't want to create an unnecessarily big request
            if (
                DEFAULT_REQUEST.hasOwnProperty( param ) &&
                config.hasOwnProperty( param ) &&
                ( config[param] || config[param] === 0 )// only truthy values
            ) {
                value = config[param];

                // if the value is a string split into array (necessary for backend).
                // This has to happen even if there is just one value.
                if ( _.isString( value ) ) {
                    value = value.split( ',' );
                }

                request[param] = value;
            }
        }

        this.request = request;
    };


    /**
     * Update the options and request properties with the an options object
     *
     * @param {Object} [options]
     *
     * @private
     */
    GetProductInfo.prototype.updateOptions = function ( options ) {
        this.options = _.extendOwn( {}, DEFAULTS, this.options, options || {} );
        this.request = _.extendOwn( {}, DEFAULT_REQUEST, this.request, options || {} );
        this.__processRequest(this.request || {});
    };


    GetProductInfo.prototype.__scrollOn = function( e ){

        var $element = e.data.that.$controlScroller;
        var top_of_element = $element.offset().top;
        var bottom_of_element = $element.offset().top + $element.outerHeight();
        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        var top_of_screen = $(window).scrollTop();
        var isVisible = false;

        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
            isVisible = true;
        }

        if( !e.data.that.stopScrollEvent && isVisible ){
            e.data.that.stopScrollEvent = true;
            e.data.that.request.page = e.data.that.page + 1;
            $( window ).off( 'scroll.zg.pagination.loader');

            if( e.data.that.request.page < e.data.that.totalPages ) {
                e.data.that.getInfo();
            }
        }
    }

    /**
     * @method __setEventHandlers
     * @listen filtersContainers#click.zg.filters.applyFilter Click on filter value
     */
    GetProductInfo.prototype.__setEventHandlers = function () {
        var that = this;

        this.$filterContainer.off( '.zg.filters.applyFilter' );

        // -------------------------------------------------------------------------

        this.$filterContainer.on( 'click.zg.filters.applyFilter', this.options.filterElement, function ( e ) {
            var $this, data;

            $this = $( this );

            if ( !$this.is( 'select' ) && !$this.is( 'option' ) && !$this.is( 'input' ) ) {
                e.preventDefault();

                data = $this.data();
                var filter = {};
                var filterValue = [];
                var isFilterActive = $this.hasClass( 'active' );
                var dataValue = data.value;
                if( dataValue ) {
                    dataValue = data.value.toString();
                }

                if( data.zgAction == 'reset' ){
                    $( this ).closest( '.content-filter' ).find( 'a.active' ).removeClass( 'active' );
                    $( this ).addClass( 'active' );
                    if( _.has(that.request.filterBy, data.filter) ){
                        delete that.request.filterBy[ data.filter ];
                    }
                }
                else {
                    that.request.page = 0;
                    if ( _.has(that.request.filterBy, data.filter) && dataValue  ) {
                        filterValue = that.request.filterBy[ data.filter ];
                    } else {
                        $( this ).closest( '.content-filter' ).find( 'a.active' ).removeClass( 'active' );
                    }

                    var indexOfFilterValue = filterValue.indexOf( dataValue );

                    // If the selected filter has not been selected before
                    // (is not active and is not part of the data object where we can find all the currently active filters)
                    if ( indexOfFilterValue == -1 && !isFilterActive ) {
                        filterValue.push( dataValue );
                        filter[ data.filter ] = filterValue.sort();
                        $( this ).addClass( 'active' );
                    }
                    else {
                        //If it has been selected before, we disable it and remove it from the data object
                        filterValue.splice( indexOfFilterValue, 1 );
                        $( this ).removeClass( 'active' );

                        //If the selected filter is the "All" option we set the All option as active and we remove that property from the filterBy object
                        if ( filterValue.length == 0 ) {
                            $( this ).closest( '.content-filter' ).find( 'a:first-child' ).addClass( 'active' );
                            delete that.request.filterBy[ data.filter ];
                        }
                    }
                }

                if( !that.request.filterBy ) {
                    that.request.filterBy = filter;
                }
                else if( _.isEmpty( that.request.filterBy ) ){
                    delete that.request.filterBy;
                }
                else{
                    that.request.filterBy = _.extend( that.request.filterBy, filter );
                }

                if( that.options.paginationType == "infinite" ){
                    that.request.page = 0;
                }
                that.options.emptyContainer = true;
                that.__emtpyContainer();

                that.getInfo( false, true );
            }
        } );


        this.$element.on( 'click.zg.filters.searchButton', this.options.searchButton, function() {
            var searched = $( '[data-zg-role="filter-search"]' ).val();
            var searched_parsed = zgParseString( searched, true );
            if ( searched_parsed !== (that.request.search || [] ) ) {
                searched = { value: searched_parsed, fields: [ 'name', 'option', 'mainoption']};
                if( !that.request.filterBy ) {
                    that.request.filterBy = {};
                    that.request.filterBy.search = searched;
                }
                else {
                    that.request.filterBy.search = searched;
                }
                that.request.page = 0;
                that.options.emptyContainer = true;
                that.__emtpyContainer();

                $('body,html').animate({
                    scrollTop: 0
                }, 500);

                that.getInfo( false, true );
            }
        } );

        this.$element.on( 'click.zg.filters.resetFilters', this.options.resetFilters, function ( e ) {
            e.preventDefault();
            that.resetFilters();
        } );


        this.$element.on( 'click.zg.pagination.sort', this.options.elementSorting + ' [data-sort-by]', function ( e ) {
            var $this = $( this );
            var sortByValue = $this.data( 'sort-by' );

            e.preventDefault();

            that.$element.find( that.options.elementSorting ).removeClass( 'active' );
            $this.closest( that.options.elementSorting ).addClass( 'active' );
            if( sortByValue ){
                that.request.sortBy = sortByValue;
            }
            else {
                delete that.request.sortBy;
            }

            //if( that.options.paginationType == "infinite" || that.options.paginationType == "load-more" ){
                that.request.page = 0;
                that.options.emptyContainer = true;
                that.__emtpyContainer();
            //}

            that.getInfo( false, true );

        } );


        this.$element.on( 'click.zg.pagination.go.to', this.options.paginationGoTo, function( e ){
            var pageNumber = $( this ).data( 'page' );
            that.request.page = pageNumber;

            if( that.options.paginationType == 'pages' ) {
                that.options.emptyContainer = true;
                that.__emtpyContainer();

                $('body,html').animate({
                    scrollTop: 0
                }, 500);
            }
            else if( that.options.paginationType == 'load-more' ){
                that.options.emptyContainer = false;
            }

            if( pageNumber != -1 ) {
                that.getInfo( false, true );
            }
        } );

    };


    // GETPRODUCTINFO DEFINITION
    // ============================

    function Plugin ( option, updateOptions ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.getProductInfo' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.getProductInfo', (data = new GetProductInfo( this, options )) );
            } else if ( updateOptions && typeof option === 'object' ) {
                data.updateOptions( option );
            }

            data.getInfo( true );
        } );
    }

    $.fn.getProductInfo             = Plugin;
    $.fn.getProductInfo.Constructor = GetProductInfo;


    // GETPRODUCTINFO DATA-API
    // ===================

    // default product - called on page load
    $( function () {
        $( SELECTOR ).each( function () {
            Plugin.call( $( this ) );
        } );
    } );

}.call( this, jQuery, _ ));
