/**
 * Get by Google API the direction route from cliente address to store and show in specific container
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event error.
 * @property {string} message - The error message to be translated.
 */

(function () { /* global _, DEBUG, google, isGoogleMapsAvailable */
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;


	/**
	 *
	 */
	var GetDirections = function ( options ) {
		/** @type {null|google.maps.DirectionsService} */
		this.directionsService = null;

		/** @type {null|google.maps.DirectionsRenderer} */
		this.directionsRenderer = null;

		this.options = _.extend( {}, GetDirections.DEFAULTS, root.ZG_CONFIG, typeof options === 'object' && options );

		this.__init();
		this.__setEventHandlers();
	};


	/**
	 * @param {string} [directionsContainer] Container of directions to print
	 */

	GetDirections.DEFAULTS = {
		directionsContainer: '[data-zg-role="sl-directions-container"]'
	};


	/**
	 * @method calculate
	 * @fires document#zg-error Display the error if ajax call for get the directions not return status ok
	 */

	GetDirections.prototype.calculate = function ( origin, destination, map, callback ) {
		var request, that;

		// remove the previous route
		this.clear();

		if ( origin && destination && map && this.directionsService ) {
			that = this;

			request = {
				origin:      origin,
				destination: destination,
				travelMode:  google.maps.TravelMode.DRIVING,
				unitSystem:  google.maps.UnitSystem.METRIC
			};

			this.directionsService.route( request, function ( result, status ) {
				if ( status === google.maps.DirectionsStatus.OK ) {
					var $close = $( '<button type="button" class="close margin-bottom-sm">&times;</button>' );

					that.directionsRenderer.setDirections( result );
					that.directionsRenderer.setMap( map );
					that.directionsRenderer.setPanel( that.$directionsContainer[0] );

					that.$directionsContainer.append( $close );

					that.$directionsContainer.removeClass( 'hidden' ).fadeIn();

				} else {

					$( document ).trigger( 'zg-error', [{
						eventType: 'empty-list',
						message:   window.JS_TRANSLATIONS.genericErrorMsg
					}] );
				}

				if ( DEBUG ) {
					console.log( 'StoreLocator.GetDirections', status, result );
				}

				if ( _.isFunction( callback ) ) {
					callback();
				}
			} );
		} else {
			$( document ).trigger( 'zg-error', [{
				eventType: 'empty-list',
				message:   window.JS_TRANSLATIONS.genericErrorMsg
			}] );
		}

	};


	GetDirections.prototype.clear = function () {
		if ( this.directionsRenderer ) {
			this.directionsRenderer.setMap( null );
			this.directionsRenderer.setPanel( null );

			this.$directionsContainer.empty().hide();
		}
	};


	GetDirections.prototype.__init = function () {
		if ( isGoogleMapsAvailable() ) {
			this.$directionsContainer = $( this.options.directionsContainer );

			this.directionsService  = new google.maps.DirectionsService();

			this.directionsRenderer = new google.maps.DirectionsRenderer( {
				suppressMarkers:     true,
				suppressInfoWindows: true
			} );
		} else if ( DEBUG ) {
			console.error( 'ZgStoreLocatorGetDirections - FAILED: google maps is not available' );
		}
	};


	GetDirections.prototype.__setEventHandlers = function () {
		var that = this;

		this.$directionsContainer.on( 'click','.close', function ( e ) {
			e.preventDefault();

			that.clear();
		} );
	};


	// -----------------------------------------------------------------------------------------------------------------

	root.ZgStoreLocatorGetDirections = GetDirections;

}.call( this ));
