/**
 *
 * Manage store locator. Create google map with pin of each single store, create list of store in ajax, geolocalize the user and create filters
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 */

/* global _, DEBUG, Promise, google, handlebarsTemplates, loadGoogleMaps, JS_TRANSLATIONS, ZgStoreLocatorGeolocationMgr,
 zgStoreLocatorCalculateDistance, ZgStoreLocatorCenterMap, ZgStoreLocatorGetDirections, ZgStoreLocatorMapMarkersMgr */

/**
 * @event document#zg-error Generic error. Used by 2002-zg-notifier.js to display the error
 * @type {object}
 * @property {string} eventType - Typology of event error.
 * @property {string} message - The error message to be translated.
 */

/**
 * @event document#zg-warning Generic warning. Used by 2002-zg-notifier.js to display the warning
 * @type {object}
 * @property {string} eventType - Typology of event message.
 * @property {string} message - The error message to be translated.
 */

/**
 * @event document#zg.storeLocator.initMap Ask to init map of store locator
 * @type {null}
 */

/**
 * @event document#zg.mapMarkersMgr.clickOnMarker Click on marker
 * @type {object}
 * @property {int} storeid - Store id
 */

/**
 * @event document#click.zg.storeLocator Click on some element of store locator for asking interaction
 * @type {null}
 */

var mapStyler = [
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e9e9e9"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dedede"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#333333"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f2f2f2"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    }
];



(function ( $ ) {
	'use strict';

	/**
	 * @selector data-zg-role="store-locator" The plugin start if there is the selector in the dom when the page load
	 */
	var SELECTOR = '[data-zg-role="store-locator"]';

	/**
	 * @param {boolean} [autoInitMap] create map on page load
	 * @param {boolean} [createMap] Create interactive map
	 * @param {boolean} [centerOnUser] If true center the map in the user location
	 * @param {boolean|string} [defaultCountry] If you put a country, the plugin center the map there. For example "Italy"
	 * @param {boolean|string|number} [defaultStore] Put store id for center the map on it
	 * @param {number} [storeZoomLevel] Set zoom level of the map
	 * @param {boolean} [mapMarkerOpensInfoWindow] If true the marker open a window with store information
	 * @param {string} [elementCmsContainer] Container element with data for get stores from cms
	 * @param {string} [elementFiltersContainer] Container element with data for get filters
	 * @param {string} [elementGetDirections] Button for display directions from user local the specific store
	 * @param {string} [elementMapContainer] Google maps container
	 * @param {string} [elementPrintMap]
	 * @param {string} [elementRequestStoreLocation]
	 * @param {string} [elementStoresContainer]
	 * @param {string} [elementViewOnMap]
	 * @param {string} [markerTemplate] Handlebars template for popup open by click to the marker
	 * @param {string} [storeTemplate] Handlebars template for single store
	 * @param {object} [mapOptions] Set up map options https://developers.google.com/maps/documentation/javascript/reference#MapOptions
	 * @param {array}  [mapStyles] Set up map styles https://developers.google.com/maps/documentation/javascript/reference#MapTypeStyle
	 * @param {array}  [stores]
	 *
	 * @param {boolean} [exportMissingGeolocation]   export missing geolocation info. Only enable this for testing
	 * @param {number}  [geolocationTimer]           ms between geolocation requests if the shop coordinates are not
	 *                                               available
	 * @param {number}  [geolocationTimerIncrement]  The more requests we do the longer we have to wait to request more
	 *                                               info, or google will just stop answering.
	 * @param {number}  [geolocationMaxIterations]   Maximun number of times the geolocation will be requested for a specific store
	 */

	var DEFAULTS = {
		autoInitMap:              true,
		createMap:                true,
		centerOnUser:             false,
		defaultCountry:           null,
		defaultStore:             null,
		storeZoomLevel:           15,
		mapMarkerOpensInfoWindow: true,
		getDirectionsToStore:     true,

		elementCmsContainer:         '[data-zg-role="get-content-cms"]',
		elementFiltersContainer:     '[data-zg-role="filters"]',
		elementGetDirections:        '[data-zg-role="sl-get-directions"]',
		elementMapContainer:         '[data-zg-role="sl-map-container"]',
		elementPrintMap:             '[data-zg-role="sl-map-image"]',
		elementRequestStoreLocation: '[data-zg-role="sl-request-store-location"]',
		elementStoresContainer:      '[data-zg-role="pagination"]',
		elementViewOnMap:            '[data-zg-role="sl-view-on-map"]',

		markerTemplate: 'storelocator-marker-info-window',
		storeTemplate:  'storelocator-store',

		mapOptions: null,
		mapStyles:  mapStyler,

		stores: null,

		exportMissingGeolocation:  false,
		geolocationTimer:          500,
		geolocationTimerIncrement: 2.5,
		geolocationMaxIterations:  10
	};


	// STORE LOCATOR CLASS DEFINITION
	// ==============================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var StoreLocator = function ( element, options ) {
		this.$element = $( element );

		this.options = _.clone( DEFAULTS );
		this.__setOptions( options );

		this.map = null;

		this.stores       = {};
		this.parsedStores = {};

		this.ready = new Promise( (function ( resolve, reject ) {
			loadGoogleMaps().then( resolve, reject );
		}).bind( this ) ).then(
			(function () {
				// external functions
				this.centerMap      = new ZgStoreLocatorCenterMap( this );
				this.mapMarkersMgr  = new ZgStoreLocatorMapMarkersMgr( this.options, this.map, this.stores );
				this.geolocationMgr = new ZgStoreLocatorGeolocationMgr( this.options, this.parsedStores );

				this.getDirections = null;

				this.distance = {
					// position to calculate stores distance from
					origin: null
				};

				this.requestedDirectionsToStore = null;

				this.selectedStore = null;

				/** @type {null|Object} */
				this.filters = null;

				this.infoWindow = null;

				if ( this.options.autoInitMap ) {
					this.initMap();
				}

				this.parseStores( this.options.stores || this.__getStoresFromCMS() );
			}).bind( this )
		);

		this.$mapContainer     = $( this.options.elementMapContainer );
		this.$filtersContainer = $( this.options.elementFiltersContainer );
		this.$cmsContainer     = $( this.options.elementCmsContainer );

		this.__setEventHandlers();
	};


	/**
	 *
	 * @param distanceOrigin
	 */
	StoreLocator.prototype.calculateStoresDistance = function ( distanceOrigin ) {
		var storeId;

		this.distanceOrigin = distanceOrigin;

		for ( storeId in this.parsedStores ) {
			if ( this.parsedStores.hasOwnProperty( storeId ) ) {
				this.__setStoreDistanceFromOrigin( storeId );
			}
		}

		// update or destroy the directions
		storeId = this.requestedDirectionsToStore || this.selectedStore || this.options.defaultStore || null;

		if ( storeId && distanceOrigin ) {
			$( this.options.elementGetDirections ).removeClass( 'hidden' ).show();

			if ( storeId ) {
				// TODO: update current directions, but only if the origin changed ...
				this.getDirectionsToStore( storeId );
			}
		} else {
			$( this.options.elementGetDirections ).removeClass( 'hidden' ).hide();

			if ( this.getDirections ) {
				this.getDirections.clear();
			}
		}
	};


	/**
	 *
	 * @param appliedFilters
	 * @returns {*}
	 */
	StoreLocator.prototype.getAddressFromFilters = function ( appliedFilters ) {
		var address = '';

		_.each( ['Country', 'State', 'Province', 'City'], function ( filter ) {
			if ( appliedFilters[filter] && appliedFilters[filter][0] ) {
				if ( address ) {
					address += ', ' + decodeURIComponent( appliedFilters[filter][0] || '' );
				} else {
					address = decodeURIComponent( appliedFilters[filter][0] || '' );
				}
			}
		} );

		// Use the search string as a fallback (if is the only selected filter)
		if ( !address ) {
			address = (appliedFilters.search && appliedFilters.search[0]) || '';
		}

		return address;
	};


	/**
	 *
	 * @param {string} storeId
	 */
	StoreLocator.prototype.getDirectionsToStore = function ( storeId ) {
		this.ready.then( (function () {
			var origin;
			var destination;

			this.requestedDirectionsToStore = null;

			if ( this.distanceOrigin && this.options.getDirectionsToStore ) {
				if ( this.parsedStores[storeId] ) {
					if ( !this.getDirections ) {
						this.getDirections = new ZgStoreLocatorGetDirections( this.options );
					}

					if (
						isNaN( this.parsedStores[storeId].fields.Latitude ) ||
						isNaN( this.parsedStores[storeId].fields.Longitude )
					) {
						this.requestStoreLocation( storeId, 0 ).then( _.partial( this.getDirectionsToStore, storeId ) );
					} else {
						origin = new google.maps.LatLng( +this.distanceOrigin.lat, +this.distanceOrigin.lng );

						destination = new google.maps.LatLng(
							+this.parsedStores[storeId].fields.Latitude,
							+this.parsedStores[storeId].fields.Longitude
						);

						this.getDirections.calculate( origin, destination, this.map, (function ( destination ) {
							this.centerMap.byLocationObject( destination, this.mapMarkersMgr.options.markerClustererMaxZoom + 1 );
						}).bind( this, destination ) );

						this.requestedDirectionsToStore = storeId;
					}
				} else {
					$( document ).trigger( 'zg-error', [{
						eventType: 'zg.StoreLocator.getDirectionsToStore - Failed',
						message:   window.JS_TRANSLATIONS.genericErrorMsg
					}] );
				}
			} else {
				if ( this.getDirections ) {
					// There is no distance origin. Destroy current distances
					this.getDirections.clear();
				}
			}
		}).bind(this));
	};


	/**
	 *
	 * @param {string} storeId
	 *
	 * @returns {null|Object}
	 */
	StoreLocator.prototype.getStoreInfo = function ( storeId ) {
		var storeInfo = null;

		if ( this.parsedStores && this.parsedStores[storeId] ) {
			storeInfo = this.parsedStores[storeId];
		}

		return storeInfo;
	};


	/**
	 *
	 * @param {string} storeId
	 *
	 * @method openInfoWindow
	 * @fires document#zg.storeLocator.selectedMarker One store marker was select
	 * @fires document#zg-warning Map is not init or there isn't store id
	 */
	StoreLocator.prototype.openInfoWindow = function ( storeId ) {
		this.ready.then( (function () {
			var position;
			var zoom = this.mapMarkersMgr.options.markerClustererMaxZoom + 1;

			if ( storeId && this.stores[storeId] ) {
				this.selectedStore = storeId;
				$( document ).trigger( 'zg.storeLocator.selectedMarker', [storeId, this.parsedStores[storeId]] );

				if ( this.map ) {
					if ( this.mapMarkersMgr.mapMarkers[storeId] ) {
						// zoom in on the marker ( and explode the clusters )
						if ( this.map.getZoom() < zoom ) {
							this.map.setZoom( zoom );
						}

						// center the map in the marker
						position = this.mapMarkersMgr.mapMarkers[storeId].getPosition();
						this.centerMap.byLocationObject( position );

						this.__reloadMapTiles();

						// force to redraw the (exploded) cluster.
						// this will assign the markers directly to the map.
						this.mapMarkersMgr.redraw();

						if ( this.options.mapMarkerOpensInfoWindow ) {
							// initialize the infoWindow just once
							if ( !this.infoWindow ) {
								this.infoWindow = new google.maps.InfoWindow();
							}

							// close the previous instance
							this.infoWindow.close();

							// create infoWindow content using a template
							this.infoWindow.setContent(
								handlebarsTemplates.render( this.options.markerTemplate, this.parsedStores[storeId] )
							);

							// Use the marker position to set the infoWindow position.
							// This will be use as default position if for any reason the marker is not assigned to the map.
							this.infoWindow.setPosition( position );

							// open the info window
							if ( this.mapMarkersMgr.mapMarkers[storeId].getMap() ) {
								// if the marker is assigned to the map the window will be linked to the map
								this.infoWindow.open( this.map, this.mapMarkersMgr.mapMarkers[storeId] );
							} else {
								// otherwise just open the window in the map
								this.infoWindow.open( this.map );
							}
						}

						this.getDirectionsToStore( storeId );
					} else {
						// request the store location, create the marker and open the info window
						this.requestStoreLocation( storeId, 0 ).then( _.partial( this.openInfoWindow, storeId ) );
					}
				}
			} else {
				$( document ).trigger( 'zg-warning', {message: JS_TRANSLATIONS['storeLocator.noStoreLocation']} );

				this.__resetSelectedStore();
			}
		}).bind( this ) );
	};


	/**
	 * Make sure the stores information is valid
	 *
	 * @param {Object} stores
	 * @private
	 */
	StoreLocator.prototype.parseStores = function ( stores ) {
		this.ready.then( (function () {
			var parsedStores = {};

			// init the stores object
			if ( _.isString( stores ) ) {
				stores = JSON.parse( stores );
			}

			if ( _.isObject( stores ) && stores.fields ) {
				// view store page ( single shop ). rebuild the object in the same structure as the list stores page
				// this shouldn't be necessary if the object is configured properly in the HTML
				stores = [stores];
			}

			_.each( stores, function ( store ) {
				parsedStores[store.id] = this.__parseSingleStore( store );
			}, this );

			this.stores = parsedStores;

			if ( this.selectedStore && !this.stores || !this.stores[this.selectedStore] ) {
				this.__resetSelectedStore();
			}

			this.mapMarkersMgr.setItems( this.stores ).then( (function () {
				this.__reloadMapTiles();
			}).bind( this ) );
		}).bind( this ) );
	};


	/**
	 * The store location is not stored in the CMS.
	 * We request the location based on the address and create the marker based on that position
	 *
	 * @param {string}  storeId
	 * @param {number}  [timer]
	 * @param {boolean} [force]
	 */
	StoreLocator.prototype.requestStoreLocation = function ( storeId, timer, force ) {
		return new Promise( (function ( resolve, reject ) {
			this.geolocationMgr.request( storeId, timer, force ).then(
				(function () {
					// create the marker based in the new location
					this.mapMarkersMgr.createNew( storeId );

					// calculate new distance
					this.__setStoreDistanceFromOrigin( storeId );

					resolve();
				}).bind( this ),
				reject
			);
		}).bind( this ) );
	};


	// STORE LOCATOR PRIVATE METHODS
	// =============================


	/**
	 *
	 * @private
	 */
	StoreLocator.prototype.initMap = function () {
		this.ready.then( (function () {
			var mapOptions;

			if ( this.map ) {
				this.__reloadMapTiles();
			} else if ( this.options.createMap && this.$mapContainer.length ) {
				// Map options defaults
				// just some sensible defaults
				mapOptions = {
					zoom:      6,
					// We have to provide a default center to initialize the map. Let's use Europe.
					center:    new google.maps.LatLng( 48, 4 ),
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					minZoom:   2,
					maxZoom:   25
				};

				// set map options
				if ( this.options.mapOptions && _.isObject( this.options.mapOptions ) ) {
					_.extendOwn( mapOptions, this.options.mapOptions );
				}

				// set map styles
				if ( this.options.mapStyles && _.isArray( this.options.mapStyles ) ) {
					mapOptions.styles = this.options.mapStyles;
				}

				// create the map
				this.map = new google.maps.Map( this.$mapContainer[0], mapOptions );

				// center the map
				this.centerMap.onDefaultLocation();

				this.$mapContainer.removeClass( 'loading' );

				if ( DEBUG ) {
					console.log( 'StoreLocator.createMap - DONE' );
				}

				this.mapMarkersMgr.setMap( this.map );
			} else {
				this.$mapContainer.hide();
			}
		}).bind( this ) );
	};


	/**
	 *
	 * @returns {Object}
	 * @private
	 */
	StoreLocator.prototype.__getStoresFromCMS = function () {
		var origin = this.$cmsContainer.data( 'zg.getContentCMS' );

		// return the contents form the cms or an empty object
		return ( origin && origin.getLatestResponse() || {} ).collection || {};
	};


	/**
	 *
	 * @param {Object} store
	 * @returns {Object}
	 * @private
	 */
	StoreLocator.prototype.__parseSingleStore = function ( store ) {
		if ( !this.parsedStores[store.id] ) {
			this.parsedStores[store.id] = store;

			this.requestStoreLocation( store.id );
		}

		return this.parsedStores[store.id];
	};


	/**
	 *
	 * @private
	 */
	StoreLocator.prototype.__reloadMapTiles = function () {
		if ( this.map ) {
			google.maps.event.trigger( this.map, 'resize' );
		}
	};


	/**
	 *
	 * @private
	 */
	StoreLocator.prototype.__resetSelectedStore = function () {
		this.selectedStore = null;

		if ( this.infoWindow ) {
			this.infoWindow.close();
		}

		$( document ).trigger( 'zg.storeLocator.selectedMarker', [null, {}] );
	};


	/**
	 * @method __setEventHandlers
	 * @listen click.zg.storeLocator On click on the store list open the info window
	 */

	/**
	 * @method __setEventHandlers
	 * @listen zg.mapMarkersMgr.clickOnMarker On click of the marker open the info window
	 */

	/**
	 * @method __setEventHandlers
	 * @listen zg.storeLocator.initMap Init the map and the markers
	 */
	StoreLocator.prototype.__setEventHandlers = function () {
		$( document ).on( 'click.zg.storeLocator', this.options.elementViewOnMap, (function ( e ) {
			var storeId = String( $( e.currentTarget ).data( 'store-id' ) );

			this.openInfoWindow( storeId );
		}).bind( this ) );

		$( document ).on( 'zg.mapMarkersMgr.clickOnMarker', (function ( e, storeId ) {
			this.openInfoWindow( storeId );
		}).bind( this ) );

		// -------------------------------------------------------------------------------------------------------------

		// request a store location
		$( document ).on( 'click.zg.storeLocator', this.options.elementRequestStoreLocation, (function ( e ) {
			var storeId = String( $( e.currentTarget ).data( 'store-id' ) );

			this.requestStoreLocation( storeId, 0, true );
		}).bind( this ) );

		// -------------------------------------------------------------------------------------------------------------

		// get directions to a store
		$( document ).on( 'click.zg.storeLocator', this.options.elementGetDirections, (function ( e ) {
			var storeId = String( $( e.currentTarget ).data( 'store-id' ) );

			this.getDirectionsToStore( storeId );
		}).bind( this ) );

		// -------------------------------------------------------------------------------------------------------------

		this.$filtersContainer.on( 'zg.filter.applyFilters', (function () {
			if ( this.infoWindow ) {
				this.infoWindow.close();
			}

			this.ready.then( this.mapMarkersMgr.clearMarkers );
		}).bind( this ) );

		this.$filtersContainer.on( 'applyFilters', (function ( e, appliedFilters, filteredItems, filteredKeys ) {

			if ( this.infoWindow ) {
				this.infoWindow.close();
			}

			this.ready.then( (function () {
				this.parseStores( filteredItems );
				this.centerMap.byAddress( this.getAddressFromFilters( appliedFilters ) );
			}).bind(this));
		}).bind( this ) );

		// -------------------------------------------------------------------------------------------------------------

		this.$cmsContainer.on( 'zg.getContentCMS.success', (function ( e, contents ) {
			this.ready.then( (function () {
				this.parseStores( contents );
			}).bind(this));
		}).bind( this ) );

		// -------------------------------------------------------------------------------------------------------------

		$( document ).on( 'zg.storeLocator.initMap', (function () {
			this.ready.then( (function () {
				this.initMap();
				this.mapMarkersMgr.setItems( this.stores );
			}).bind(this));
		}).bind( this ) );
	};


	/**
	 *
	 * @param {Object} options
	 */
	StoreLocator.prototype.__setOptions = function ( options ) {
		_.extendOwn( this.options, options || {} );
	};


	/**
	 *
	 * @param {string} storeId
	 * @private
	 */
	StoreLocator.prototype.__setStoreDistanceFromOrigin = function ( storeId ) {
		var distance = zgStoreLocatorCalculateDistance(
			this.distanceOrigin,
			{
				lat: this.parsedStores[storeId].fields.Latitude,
				lng: this.parsedStores[storeId].fields.Longitude
			}
		);

		this.parsedStores[storeId].distanceFromOrigin = distance;

		if ( _.isNumber( distance ) ) {
			if ( distance < 1 ) {
				distance = '' + ( distance * 1000 ) + ' meters';
			} else {
				distance = '' + distance + ' Km';
			}
		}

		this.parsedStores[storeId].KmFromOrigin = distance;
	};


	// STORE LOCATOR PLUGIN DEFINITION
	// ===============================

	var Plugin = function Plugin ( option ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.storeLocator' );
			var options = $.extend( {}, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.storeLocator', new StoreLocator( this, options ) );
			} else if ( option ) {
				data.__setOptions( options );
			}
		} );
	};

	$.fn.StoreLocator             = Plugin;
	$.fn.StoreLocator.Constructor = StoreLocator;


	// STORE LOCATOR DATA-API
	// ======================

	$( function () {
		$( SELECTOR ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}( jQuery ));
