/**
 * Manage User Point in account page
 *
 * @author: Jetaly <jxiao@zerogrey.com>
 *
 */
(function ( $ ) {

	"use strict";

	/**
	 * @selector data-zg-role="user-points-container" The plugin start if there is the selector in the dom when the page load
	 */
	var toggle  = '[data-zg-role="user-points-container"]';

	var userPointsSurplusToggle				= '[data-zg-role="user-points-surplus"]';
	var userPointsMinPointsToggle			= '[data-zg-role="user-points-min-points"]';

	var discountOptionToggle				= '[data-zg-role="discount-option-container"]';

	var convertPointsAmountToggle			= '[data-zg-role="convert-points-amount"]';
	var convertPointsBtnToggle				= '[data-zg-role="convert-points-btn"]';

	var convertPointsConfirmationToggle		= '[data-zg-role="convert-points-confirmation"]';
	var convertPointsAmountConfirmToggle	= '[data-zg-role="convert-points-amount-confirm"]';
	var convertPointsYesToggle				= '[data-zg-role="convert-points-modal-yes"]';

	var unusedCodesTableToggle				= '[data-zg-role="unused-codes-table"]';
	var noCodesWarningToggle				= '[data-zg-role="no-codes_warning"]';

	var redeemListTableToggle				= '[data-zg-role="redeem-list-table"]';
	var redeemPointsBtnToggle				= '[data-zg-role="redeem-points-btn"]';
	var redeemPointsConfirmationToggle		= '[data-zg-role="redeem-points-confirmation"]';
	var redeemPointsNeededConfirmToggle		= '[data-zg-role="redeem-points-needed-confirm"]';
	var redeemNameConfirmToggle				= '[data-zg-role="redeem-name-confirm"]';
	var redeemPointsYesToggle				= '[data-zg-role="redeem-points-modal-yes"]';

	var pointsHistoryTableToggle			= '[data-zg-role="points-history-table"]';


	var ZG_CreateDiscount = function( element, config ){

		var self = this;

		this.init = function( element, config ){
			var $element 	= $(element);
			self.$element 	= $element;
			self.$eventBus 	= $(document);

			self.$discountOption   = $element.find( discountOptionToggle );

			self.$convertPointsForm   	= self.$discountOption.find( 'form' );
			self.$convertPointsAmount	= self.$convertPointsForm.find( convertPointsAmountToggle );
			self.$convertPointsBtn   	= self.$convertPointsForm.find( convertPointsBtnToggle );


			self.$convertPointsConfirmation = self.$discountOption.find( convertPointsConfirmationToggle );
			self.$convertPointsYes          = self.$convertPointsConfirmation.find( convertPointsYesToggle );


			self.$redeemPointsBtn 			= self.$discountOption.find(redeemPointsBtnToggle);
			self.$redeemPointsConfirmation 	= self.$discountOption.find(redeemPointsConfirmationToggle);
			self.$redeemPointsYes   		= self.$redeemPointsConfirmation.find( redeemPointsYesToggle );

			self.redeemDefinitionId = 0;

			self.$convertPointsBtn.on('click', self.validatePointsAmount);
			self.$convertPointsYes.on('click', self.generateNewDiscountCode);

			self.$redeemPointsBtn.on('click', self.confirmRedeem);
			self.$redeemPointsYes.on('click', self.generateNewDiscountCode);
		};


		this.validatePointsAmount = function(){

			var surplusPoints = parseInt(self.$element.find( userPointsSurplusToggle ).html());

			var minPoints = parseInt(self.$element.find( userPointsMinPointsToggle ).html());


			if( surplusPoints >= minPoints ){
				event.preventDefault();
				if ( self.$convertPointsForm.data('zgValidator') ) {
					if ( !self.$convertPointsForm.data('zgValidator').validate() ) {
						return false;
					}
				}

				self.$convertPointsConfirmation.find( convertPointsAmountConfirmToggle ).html( self.$convertPointsAmount.val() );
				self.$convertPointsConfirmation.modal();
			}else{
				var errorMsgElement = self.$convertPointsAmount.next('.text-error');

				if (errorMsgElement.data('zg-role') == 'points-amount-error-msg') {
					errorMsgElement.show();
				}

				self.$convertPointsAmount.attr('disabled', true);
				self.$convertPointsBtn.attr('disabled', true);
			}
		};



		this.confirmRedeem = function( event ){
			self.redeemDefinitionId = $(event.currentTarget).val();

			self.$redeemPointsConfirmation.find( redeemPointsNeededConfirmToggle ).html( $(event.currentTarget).data('points-needed') );
			self.$redeemPointsConfirmation.find( redeemNameConfirmToggle ).html( $(event.currentTarget).data('redeem-name') );

			self.$redeemPointsConfirmation.modal();
		};

		this.generateNewDiscountCode = function( event ){

			if( $(event.currentTarget).data('zg-role') == 'redeem-points-modal-yes' ){
				self.$redeemPointsConfirmation.modal('hide');
				var request = {type:'redeem', definitionId:self.redeemDefinitionId};
			}else{
				self.$convertPointsConfirmation.modal('hide');
				var request = {type:'convert', points:self.$convertPointsAmount.val()};
			}

			try{
				$.ajax({
					type: 'post',
					url: window.makeUrl({module:'eshop',action:'generateDiscountCode'}),
					data : request,
					dataType: 'json',
					success: function (response) {
						self.processResponse( response );
					},
					error: function (response) {
						self.$eventBus.trigger('zg-error', [{
							eventType: 'custom-product',
							message: window["JS_TRANSLATIONS"]["genericErrorMsg"] || "An error has occurred. Please try again later." // change this
						}]);
					}
				});
			} catch (err) {
				console.log(err);
			}
		};

		this.processResponse = function( response ){

			if (response.status == 'success') {

				self.$element.find( userPointsSurplusToggle ).html( response.surplus_points );

				if( response.surplus_points <= 0 ){
					self.disableConvert( response.convert_points_error_msg );
				}else{
					self.$convertPointsAmount.attr('max', response.surplus_points);
				}

				self.$element.find( unusedCodesTableToggle ).append( $('<tr>').append(
						$('<td>').css('text-align', 'left').html(response.discount_code),
						$('<td>').html(response.discount_discription),
						$('<td>').html(response.discount_date)
				));

				if ( self.$element.find( unusedCodesTableToggle ).hasClass( 'hidden' ) ) {
					self.$element.find( unusedCodesTableToggle ).removeClass( 'hidden' );
					self.$element.find( noCodesWarningToggle ).remove();
				}

				self.resetRedeemButton( response.surplus_points, response.redeem_text );

				self.$element.find( pointsHistoryTableToggle ).append( $('<tr>').append(
						$('<td>').css('text-align', 'left').html( response.history_date ),
						$('<td>').html(response.history_points),
						$('<td>').html(response.history_created_by)
				));

				self.$eventBus.trigger('zg-notification', [{
					eventType: 'custom-product',
					message: response.message
				}]);

			 }else{
				 self.$eventBus.trigger('zg-error', [{
					 eventType: 'custom-product',
					 message: (response.message || window["JS_TRANSLATIONS"]["genericErrorMsg"])
				 }]);
			}
		};

		this.disableConvert = function( errorMsg ){
			var errorMsgElement = self.$convertPointsAmount.next('.text-error');

			if (errorMsgElement.data('zg-role') != 'points-amount-error-msg') {
				self.$convertPointsAmount.after(' <span data-zg-role="points-amount-error-msg" class="text-error" style="display:none;"><i class="fa fa-info-circle"></i> '+errorMsg+'</span>');
			}
		};


		this.resetRedeemButton = function( surplusPoints, redeemText ){
			self.$discountOption.find( redeemListTableToggle ).find( 'tbody tr td:last-child' ).each( function(){
				if( parseInt($(this).data('points-needed')) > parseInt(surplusPoints) ){
					var v = new Array();
					v[0] = parseInt( $(this).data('points-needed') ) - parseInt( surplusPoints );
					$(this).html( window["JS_TRANSLATIONS"]["translate_p"](redeemText, v) );
				}
			});
		};

		this.init( element, config );
	};


	$.fn.zg_create_discount = function( config ){
		return this.each(function(){
			var $this = $(this);
			var data = $this.data('zgCreateDiscount');

			if(!data){
				$this.data('zgCreateDiscount', (data = new ZG_CreateDiscount(this, config)));
			}
		});
	};


	$(function(){
		$(toggle).zg_create_discount( window.ZG_CONFIG );
	});

})( jQuery );