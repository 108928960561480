// add your site specific javascript here
(function ( $ ) {
	'use strict';

	$.ajaxSetup( {
		cache: true
	} );

	//For crawler
	if ( !window.JS_ENABLED ) {
		$.ajax( {
			url:      window.makeUrl( { module: 'eshop', action: 'setJavascriptEnabled' } ),
			type:     'post',
			dataType: 'json'
		} );
	}

	// -----------------------------------------------------------------------------------------------------------------
	// Anything to be initialized on document ready goes here

	$( function () {
		//First modal registration
		$( '#registrationModalThanks' ).modal( 'show' );

		// If we have an "active" tab we start with that one selected, otherwise we select the first one
		$( '.nav-tabs' ).each( function () {
			var $activeTab = $( this ).find( '.active [data-toggle="tab"]:first' );

			if ( $activeTab.length ) {
				$activeTab.tab();
			} else {
				$( this ).find( '[data-toggle="tab"]:first' ).tab();
			}
		} );

	} );

	$( function () {
		$( this ).find( '[data-zg-role="search-input"]').each( function(){
				//Bind the search function
				$( this ).on('input paste', function(){


					// Retrieve the input field text and reset the count to zero
					var filter = $(this).val(), count = 0;

					// Loop through the comment list
					$('[data-zg-role="search-items"]').each(function(){

						// If the list item does not contain the text phrase fade it out
						if ($(this).text().search(new RegExp(filter, "i")) < 0) {
							$(this).fadeOut();

							// Show the list item if the phrase matches and increase the count by 1
						} else {
							$(this).show();
							//if is the first visible I also show the function definition
							/*if(count == 0){
								console.log($(this).find('a'));
							}*/

							count++;
						}
					});

					// Update the count
					if(count > 0){
						$("#noResult").addClass('hide');
					}
					else{
						$("#noResult").removeClass('hide');
					}
					$("#filter-count").text(count);
					$(".count-hide").removeClass('hide');

				});
			}
		);

	});

	//Scroll slowly to the target
	$( function () {
		$('[data-zg-scrollTo="slowly"]').click(function () {
			var target = $(this).data('zg-target'),
				href = $(this).attr("href"),
				offsetInserted = $(this).data('zg-offset'),
				offset = $(target).offset().top;

			if( offsetInserted ){
				offset -= offsetInserted;
			}
			$('html, body').animate({scrollTop: offset}, 500);
			window.location.hash = href;
		});
	});


	// Redirecting to the "Messages" tab in the My Acoount page when the user clicks on "View my messages"
	if ( SGL_JS_MANAGER === 'account' && window.location.hash === '#tickets' ) {
		$( '#tabs-account li a[href="#tickets"]' ).tab( 'show' );
	}


	// Fading out the bouncing arrow for the Home Page slider
	$( window ).scroll( function(){
		$(".arrow").css("opacity", 1 - $(window).scrollTop() / 250);
		//250 is fade pixels
	});


	// ===== Scroll to Top ====
	$( window ).scroll( function() {
		if ( $( this ).scrollTop() > 200) {        // If the page is scrolled more than 200px
			$( '#return-to-top' ).fadeIn( 200 );
		} else {
			$( '#return-to-top' ).fadeOut( 200 );
		}
	});
	$( '#return-to-top' ).click(function() {
		$( 'body,html' ).animate({
			scrollTop : 0
		}, 500);
	});

	//Increasing-decreasing qty input
	$( '[data-zg-role="change-qty"]' ).click( function(){
		event.preventDefault();
		var type = $( this ).data( 'type' );
		var $inputQtySibling = $( this ).parent().siblings( '[data-zg-role="quantity"]' );
		var maxQty = $inputQtySibling.data( 'max' );
		var floatQty = $inputQtySibling.data( 'floatQty' );
		var qty = $inputQtySibling.val();
		var step;

		if( floatQty ){
				step = 0.01;
		}
		else {
				step = 1;
		}
		qty = Number(qty);
		
		$( '[data-zg-role="change-qty"]' ).attr( 'disabled', false );
		if( type == "minus" ){
			//If qty is 0, we will disable the current minus icon. 
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == 0 ){
				$( this ).attr( 'disabled', true );
			}
			else{
				qty = qty - step;
			}
		}
		else {
			//If qty is equal to maxQty, we will disable the current plus icon. 
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == maxQty ){
					$( this ).attr( 'disabled', true );
			}
			else {
				qty = qty + step;
			}
		}

		$( this ).parent().siblings( '[data-zg-role="quantity"]' ).val( qty );
	});
	
	//Dueper
	
	window.lazyLoadInstance = new LazyLoad({
		// Your custom settings go here
	});

	var searchContainer = $('#searchContainer')
	var menuOverlay = $('#menu-overlay')
	var mainHeader = $('.main-header')
	
	function closeSearch() {
		searchContainer.removeClass('is-active')
		mainHeader.removeClass('search-opened')	
	}
	
	//Search cass
	searchContainer.find('[data-close]').on('click', function() {
		closeSearch()
	});
	
	$( '[data-zg-role="btn-search"]' ).click( function(e){
		e.preventDefault();
		searchContainer.toggleClass( 'is-active' );
		mainHeader.toggleClass('search-opened')
		
		if (searchContainer.hasClass('is-active')) {
			searchContainer.find('.search-input').focus()
		}
	});
	
	//Overlay toggle
	$('#menu-overlay-toggle').on('click', function(e) {
		e.preventDefault();
		
		$(this).toggleClass('is-active')
		menuOverlay.toggleClass('is-active')
		mainHeader.toggleClass('is-active')
		//Close search
		closeSearch()
	});
	
	//Change header bg on scroll
	$( window ).scroll( function() {
		if ( $( this ).scrollTop() > 50) {        // If the page is scrolled more than 200px
			mainHeader.addClass( 'white-header' );
		} else {
			mainHeader.removeClass( 'white-header' );
		}
	});
	
	//Slider
	var mySwiper = new Swiper('.slider-component', {
		loop: true,
		lazy: {
			loadPrevNext: true,
			loadPrevNextAmount: 1,
		},
		preloadImages: true,
		
		// If we need pagination
		pagination: {
			type: 'fraction',
			el: '.swiper-pagination',
			renderFraction: function (currentClass, totalClass) {
      	return '<span class="' + currentClass + '"></span>' + '<hr class="sep" />' + '<span class="' + totalClass + '"></span>';
  		}	
		},
		
		speed: 600,
		
		autoplay: {
			delay: 5000,
		},

		// Navigation arrows
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	})

	
	//Category page prreview size
	var sizeToggler = $('[data-size-toggle]')
	var sizeTarget = $('.category-list')
	
	sizeToggler.on('click', function() {
		var size = $(this).attr('data-size-toggle')
		
		sizeToggler.removeClass('is-active')
		$(this).addClass('is-active')
		
		if (size === 'large') {
			sizeTarget.addClass('preview-large')
		} else if (size === 'small') {
			sizeTarget.removeClass('preview-large')
		}
	})
	
	$(document).on('filters.renderFilters', function() {
		
		var filterToggles = $('[data-toggle="filter-collapse"]')

		filterToggles.map(function() {

			var target = $(this).attr('href')
			var targetEl = $(target)
			
			$(this).on('closeFilter', function() {
				targetEl.removeClass('open')
				$(this).removeClass('open')
			})

			$(this).on('click', function(e) {
				e.preventDefault()
				
				var clicked = this
				
				filterToggles.filter(function(index, el) {
					if (el !== clicked) {
						$(el).trigger('closeFilter')
					}
				})
				
				targetEl.toggleClass('open')
				$(this).toggleClass('open')
			})
		});
	})
	
	//Video product autoplay
	$(window).on('load', function() {
		var video = $('.manager-product video.product-video');
		
		console.log(video)
		if (video.length > 0) {
			video.each(function() {
				this.play();
			})
		}
	});

})( jQuery );