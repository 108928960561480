/**
 * zg-comments.js
 *
 * Plugin for manage comments and rating of products
 * - display list of comments
 * - add comments and rating by form
 *
 * @author: @author: Andrea Basile <abasile [at] koooomo [dot] com>
 *
 */


/**
 * @event document#submit.zg.comment Submit the comment
 * @type {object}
 * @property {string} content - text of the comment
 * @property {int} productId - id of the product commented.
 * @property {int} score - score value of product.
 */

/**
 * @event document#click.zg.comment Read more/less comments
 * @type {object}
 * @property {string} mode - less or more
 */


/* global zgPost, handlebarsTemplates */

(function ($, _) {

    'use strict';

    var root = this;

    /**
     * @selector data-zg-role="comments" The plugin start if there is the selector in the dom when the page load
     */

    var selector = '[data-zg-role="comments"]';

    /**
     * @param {int} [productId] the id of the product in current page
     * @param {int} [limitComments] max of comment under the product
     * @param {int} [userId] the id of the user for submit a comment
     * @param {bool} [alreadyRated] control value to check if surrent user has alredy rated the product
     * @param {string} [textSelector] selector for the text in the form comment
     * @param {string} [scoreSelector] selector for the rating in the form comment
     * @param {string} [formSelector] selector for the form comment
     * @param {string} [listCommentsSelector] selector for container of the comment list
     * @param {string} [singleCommentSelector] selector for the each single comments
     * @param {string} [pagingElement] selector for the paging of comments list
     * @param {string} [pageModeSelector] selector for buttons to read all comments
     *
     */

    var DEFAULTS = {
        //DATA
        productId: 0,
        limitComments:0,
        userId:0,
        alreadyRated:false,

        //SELECTOR
        textSelector:'[data-text-form="comment-text"]',
        scoreSelector:'[data-zg-role="comment-star-rate"]',
        formSelector: '[data-zg-role="comment-form"]',
        listCommentsSelector:'[data-zg-role="comment-list"]',
        singleCommentSelector:'[data-zg-role="comment-single"]',
        pagingElement: '[data-zg-role="comment-paging"]',
        pageModeSelector:'[data-zg-role="comment-read"]'
    };

    /**
     *
     * @param {HTMLElement} element
     * @param {!object}     options
     *
     * @constructor
     */

    var Comment = function ( element, options ) {
        this.$element                   = $( element );
        this.options                    = _.extendOwn( _.clone( DEFAULTS ), options || {} );

        this.$formContainer             = this.$element.find( this.options.formSelector );
        this.$listCommentsContainer     = this.$element.find( this.options.listCommentsSelector );
        this.$pagingContainer           = this.$element.find( this.options.pagingElement );

        // initialize object
        this.loadMessages();
        this.__eventHandlers();
    };

    /**
     * If the products contains messages, the function show the messages and rating
     *
     * @method loadMessages
     * @fires document#zg-error Request Failed.
     */

    Comment.prototype.loadMessages = function () {

        var data = {
                productId: this.options.productId,
                setLimit: this.options.limitComments
            };

        zgPost( 'getProductCommentsAll', data, null, {
            success: _.bind( function ( response ) {
                if (response.length > 0) {
                    this.$listCommentsContainer.html(handlebarsTemplates.render('comment', {comments: response})).fadeIn();

                    //limit visible comments
                    $( this.options.singleCommentSelector, this.$element ).slice(0, this.options.limitComments).removeClass('hidden');

                    if( this.options.limitComments >= response.length ){
                        this.$pagingContainer.hide();
                    }

                    //Already rated
                    _.each(response,function( value){
                        if( parseInt(value.user_id) === this.options.userId){
                            this.options.alreadyRated = true;
                        }
                    }, this );
                }
            }, this ),
            error: _.bind( function () {
                $( document ).trigger( 'zg-error', [{
                    eventType: 'comment',
                    message:   ('Request Failed')
                }] );
            }, this )
        });

    };

    /**
     * @method submitForm
     * @fires document#zg-error if all data is not inserted
     */

    /**
     * @method submitForm
     * @fires document#zg-error generic error if ajax call return an error
     */

    /**
     * Adds the comment to product.
     *
     */
    Comment.prototype.submitForm = function (){
        var data = {
            content: $(this.options.textSelector,this.$formContainer).val() || '',
            productId: this.options.productId || 0,
            score:  $(this.options.scoreSelector,this.$formContainer).find('input[type="radio"]:checked').val()||0
        };

        if(data.content === '' || data.score === 0 || data.productId === 0){
            $( document ).trigger( 'zg-error', [{
                eventType: 'sendComment',
                message: root.JS_TRANSLATIONS['commentSystem.generic.first'] || 'Check the data!'
            }] );
            return;
        }
        zgPost( 'addUserComments', data,  {
            success: root.JS_TRANSLATIONS['commentSystem.Success'] || 'Your comment has been submitted. ',
            error: root.JS_TRANSLATIONS['genericErrorMsg'] || 'An error has occurred. Please try again later.'
        },{
            success: _.bind( function ( ) {
                this.$formContainer.find('input').attr('disabled',true);
                this.$formContainer.find('textarea').attr('disabled',true);

            }, this ),
            error: _.bind( function () {

            }, this )
        });

    };



    /**
     * @method __eventHandlers
     * @listen document#submit.zg.comment. On submit adds the comment to product
     */
    /**
     * @method __eventHandlers
     * @listen document#click.zg.comment. read more or less comment depends if btn clicked has more/less mode value
     *
     */

    Comment.prototype.__eventHandlers = function () {

        this.$formContainer.on( 'submit.zg.comment', (function ( e ) {
            e.preventDefault();
            if(this.options.alreadyRated){
                $( document ).trigger( 'zg-error', [{
                    eventType: 'sendComment',
                    message: root.JS_TRANSLATIONS['commentSystem.error.alreadyRated']  || 'You have already rated this product.'
                }] );
                return;
            }else {
                this.submitForm();
            }
        }).bind( this ) );

        this.$element.on( 'click.zg.comment', this.options.pageModeSelector, (function (e) {
            e.preventDefault();

            var $this = $( e.currentTarget),
                mode = $this.data('mode');

            if( mode === 'more') {
                $( this.options.singleCommentSelector, this.$element ).removeClass('hidden');
                this.$element.find( this.options.pageModeSelector ).removeClass('hidden');
                $this.addClass('hidden');
            }
            else if( mode === 'less') {
                $( this.options.singleCommentSelector, this.$element ).addClass('hidden');
                $( this.options.singleCommentSelector,this.$element ).slice(0, this.options.limitComments).removeClass('hidden');
                this.$element.find( this.options.pageModeSelector ).removeClass('hidden');
                $this.addClass('hidden');
            }
        } ).bind( this ) );

    };

    // COMMENT PLUGIN DEFINITION
    // =========================
    function Plugin ( option ) {
        return this.each( function () {
            var $this   = $( this );
            var data    = $this.data( 'zg.comment' );
            var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

            if ( !data ) {
                $this.data( 'zg.comment', new Comment( this, options ) );
            }
        } );
    }

    $.fn.zg_comment             = Plugin;
    $.fn.zg_comment.Constructor = Comment;

    //START PLUGIN
    $( function () {
        $( selector ).each( function () {
            Plugin.call( $( this ) );
        } );
    } );


}).call( this, jQuery, _ );
